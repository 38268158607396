import React from 'react'

const Banner = () => {
    return (
        /* <!-- Representa el Banner que procede al Logo del sitio web --> */
						<section id="banner">
                        <header>
                            <h2>Mapa de Seguridad</h2>
                            <p>Alertemos a los dem&#225;s sobre d&#243;nde tener cuidado</p>
                        </header>
                    </section>
    )
}

export default Banner
