import React from 'react'
import Mapa2 from './Mapa2.js'

        /* <!-- Representa la sección Mapa --> */

function Mapa() {
    return (
       
				<section id="main">
					<div class="container">
						<div class="row">
							<div class="col-12">
</div>
								
									<section>
										<header class="major">
											<h2 id="Mapa">Mapa</h2>
											<h4>Si hac&#233;s click en el punto de inter&#233;s podr&#225;s ver los detalles</h4>
										</header>
                                        <Mapa2 />
  

												</section>
											</div>
										</div>
									</section>
                                  
							
                   
    )               
}

export default Mapa
