import React from 'react'
import './css/main.css';

function Proyecto() {
    return (
<div>
            <div class="col-12">

{/* <!-- Representa la sección proyecto donde se brinda información del mismo --> */}
    <section>
        <header class="major">
            <h2><span class="proyecto">El Proyecto</span></h2>
        </header>
        <div class="row">
            <div class="col-6 col-12-small">
                <section class="box">
                    
                    <header>
                        <h3><span class="proyecto">Nacimiento</span></h3>
                        <p>A raiz de una ola de casos que se dieron a conocer en el ultimo tiempo de intentos de secuestro y robos, mayoritariamente en mujeres, decidi aportar un granito de arena para que entre todos/as nos podamos cuidar y alertar sobre esquinas peligrosas de nuestra Ciudad. <br></br><br></br><br></br><br></br></p>
                    </header>
                    <footer>
                        
                    </footer>
                </section>
            </div>
            <div class="col-6 col-12-small">
                <section class="box">
                    
                    <header>
                        <h3><span class="proyecto">Objetivo</span></h3>
                        <p>Adem&#225;s de alertarnos entre todos/as y ayudar a cuidarnos, el objetivo del proyecto es hacer ruido para que quienes deben llevar adelante las medidas de protecci&#243;n puedan tener esta info a mano y hacer algo. <br></br>Se establece una pol&#237;tica de datos abiertos con lo cual la informaci&#243;n estar&#225; al alcance de cualquier persona que lo requiera.</p>
                    </header>
                    <footer>
                    </footer>
                </section>
            </div>
        </div>
    </section>

</div>
</div>
  
        
    )
}

export default Proyecto
