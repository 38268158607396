import React from 'react'

        /* <!-- Representa el mapa incrustado --> */

function Mapa2() {
    return (
        <div>
            <iframe src="./Filtros/index.html" height="600" allow="fullscreen"></iframe>								
        </div>
    )
}

export default Mapa2
