import React from 'react'

function Footer() {
    return (
        // <!-- Representa el Footer que contiene las novedades y los datos de contacto -->
				<section id="footer">
					<div class="container">
						<div class="row">
							<div class="col-8 col-12-medium">
								<section>
									<header>
										<h2 id="Novedades">Novedades</h2>
									</header>
									<ul class="dates">
										<li>
											<span class="date">May <strong>14</strong></span>
											<h3>Se permite guardar fecha del incidente y franja horaria. Ademas se agrega la opci&#243;n de ir al sitio web de la Fiscalia </h3>
										</li>
										<li>
											<span class="date">May <strong>12</strong></span>
											<h3>C&#243;rdoba Segura se da a conocer en los medios</h3>
										</li>
										<li>
											<span class="date">May <strong>6</strong></span>
											<h3>C&#243;rdoba Segura sale a la luz</h3>
											
										</li>
										<li>
											<span class="date">May <strong>1</strong></span>
											<h3>Ante la ola de casos surge la idea de Mapear los incidentes</h3>
										</li>
									</ul>
								</section>
							</div>
							
							
							<div class="col-4 col-12-medium">
								<section>
									<header>
										<h2 id="Contacto">Contactame</h2>
									</header>
									<ul class="social">
										<li><a class="icon brands fa-twitter" href="http://www.twitter.com/lucho01025" target="_blank"></a></li>

										<abbr title="proyectocbasegura@gmail.com"><li><a class="fas fa-envelope-square" href="mailto:proyectocbasegura@gmail.com"></a></li></abbr>
									</ul>
									
								</section>
							</div>
							<div class="col-12">

								{/* <!-- Copyright --> */}
									<div id="copyright">
										<ul class="links">
											<li>&copy; C&#243;rdoba Segura. Todos los derechos reservados.</li><li>Dise&#241;o: 912</li>
										</ul>
									</div>

							</div>
						</div>
					</div>
				</section>
    )
}

export default Footer
