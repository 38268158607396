import Logo from './Logo.js'
import Banner from './Banner.js'
import Nav from './Nav.js'
import Pasos from './Pasos.js'
import Mapa from './Mapa.js'
import Proyecto from './Proyecto.js'
import Footer from './Footer.js'

function App() {
  return (
      <div>
      <section id="header">
      <Logo />
      <Nav />
      <Banner />
      <Pasos />
      <Mapa />
      </section>
      <section id="footer">
      <Proyecto />
      <Footer />
      </section>
      </div>
      
  );
}

export default App;
