import React from 'react'

const Logo = () => {
    return (
        // <!-- Representa la frase o logo que encabeza el sitio web -->
						<h1><a href="index.html">C&#243;rdoba Segura</a></h1>
    )
}

export default Logo
