import React from 'react'

        /* <!-- Representa los pasos a seguir para proceder con la denuncia.  --> */

const Pasos = () => {
    return (
						<section id="intro" class="container">
							<div class="row">
								<div class="col-4 col-12-medium">
									<section class="first">
										<i class="icon solid featured alt fa-align-right"></i>
										<header>
											<h2>Paso 1</h2>
										</header>
										<p>Complet&#225; el formulario con la descripci&#243;n del hecho.</p>
									</section>
								</div>
								<div class="col-4 col-12-medium">
									<section class="middle">
										<i class="icon solid featured alt fa-map-marker-alt"></i>
										<header>
											<h2>Paso 2</h2>
										</header>
										<p>Indic&#225; en el mapa d&#243;nde sucedi&#243;</p>
									</section>
								</div>
								<div class="col-4 col-12-medium">
									<section class="last">
										<i class="icon solid featured alt fa-history"></i>
										<header>
											<h2>Paso 3</h2>
										</header>
										<p>En 24 horas se ver&#225; reflejado en el mapa.</p>
									</section>
								</div>
								
								
							</div>
							<footer>
								<ul class="actions">
									<li>
										
										<a href="./Formulario.html" class="button large" target="_blank">Comenzar</a></li>
									
								</ul>
								<div class="col-4 col-12-medium">
									<section class="last">
										<i class="icon solid featured alt fa-question"></i>
										<header>
											<h2>¿C&#243;mo continuar?</h2>
										</header>
										<p>Recomendamos luego hacer la denuncia a Fiscalia haciendo click <a href="http://www.mpfcordoba.gob.ar/como-hacer-las-denuncias-online/" target="_blank">aqui.</a></p>
									</section>
								</div>
							</footer>
						</section>

				
    )
}

export default Pasos
