import React from 'react'

const Nav = () => {
    return (
        // <!-- Esta es la pestaña de navegación. Cada elemento <li> representa una opción de selección -->
						<nav id="nav">
							<ul>
								<li class="current"><a href="index.html">Home</a></li>
								<li><a href="#Mapa">Mapa</a></li>
								<li>
									<a href="#Proyecto">Proyecto</a>
								</li>
								
								<li>
                                    <a href="./Formulario.html" target="_blank">Alertar</a>
                                </li>
								<li>
                                    <a href="#Contacto">Contacto</a>
                                </li>
							</ul>
						</nav>
    )
}

export default Nav
